import './App.css';


function App() {
  return (
    <div className="App">
      <div className="App-content">
        <div className="App-list" >
          <p className="App-page-title-text">Philosophical Technologies</p>
          <div className="App-row" >
            <img src="/images/chess_launcher.png" className="App-logo" alt="logo" />
            <div className="App-col" >
              <p className="App-title">Chess Clock</p>
              <p className="App-desc"> A game clock (chess clock) for chess. </p>
              <a className="App-link" href='https://play.google.com/store/apps/details?id=com.philosophicaltechnologies.apps.chess_clock&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img className="App-link-img" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
            </div>
          </div>
          <div className="App-row">
            <img src="/images/dice_launcher.png" className="App-logo" alt="logo" />
            <div className="App-col" >
              <p className="App-title">Dice Roller</p>
              <p className="App-desc">A dice rolling application to roll dice.</p>
              <a className="App-link" href='https://play.google.com/store/apps/details?id=com.philosophicaltechnologies.apps.dice&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img className="App-link-img" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
            </div>
          </div>
          <div className="App-row">
            <img src="/images/list_launcher.png" className="App-logo" alt="logo" />
            <div className="App-col" >
              <p className="App-title">Task List</p>
              <p className="App-desc">An application for managing tasks and lists.</p>
              <a className="App-link" href='https://play.google.com/store/apps/details?id=com.philosophicaltechnologies.apps.task_list&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img className="App-link-img" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
            </div>
          </div>
        </div>
      </div>
      <div className="App-footer" >
        <p className="App-footer-text">&copy; Philosophical Technologies, 2022</p>
      </div>
    </div>
  );
}

export default App;
